import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet'
import Style from "../styles/global.css"
//* This example requires Tailwind CSS v2.0+ */
export default function NotFound() {
    return (
      <div className="bg-white min-h-screen flex flex-col lg:relative">
          <Helmet title="404 - Seite nicht gefunden - Wuppertal360.app" />
        <div className="flex-grow flex flex-col">
          <main className="flex-grow flex flex-col bg-white">
            <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 pt-10 sm:pt-16">
                <a href="/" className="inline-flex">
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-12 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
              </div>
              <div className="flex-shrink-0 my-auto py-16 sm:py-32">
                <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">Fehler 404</p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Seite nicht gefunden</h1>
                <p className="mt-2 text-base text-gray-500">Sorry, wir konnten die Seite nicht finden.</p>
                <div className="mt-6">
                  <Link to="/" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                    Zurück zur Startseite<span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              </div>
            </div>
          </main>

        </div>
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <iframe
            className="absolute inset-0 h-full w-full object-cover"
            src="https://landhaus-bilstein.wuppertal360.app"
            alt=""
          />
        </div>
      </div>
    )
  }
